body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.title{
  display: flex;
  margin: 0 auto;
  margin-left: 70px;
  margin-top: 2px;
  margin-bottom: 20px;
  width:150px;
  
}
.title p{
  margin: 0 auto;
  padding: 2px 2px;
}

#confusion_matrix_for_all_cat{
  background-color: rgb(228, 228, 228,.6);
  
}
.title2{
  display: flex;
  margin: 0 auto;
  padding: 5px 0px;
  padding-left: 50%;
  font-weight: bold;
}

.default{
  background-color: rgb(228, 228, 228,.9);
}


/* Shapes and other stuff for D3*/
#circle {
  background: lightblue;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.rect_selected{
  fill: grey!important;
 
}




#standard-basic{
  padding: 0!important;
  padding-bottom: 2px!important;
  margin-top: 6px;
}
.toogle{
  fill:brown!important;
}

      .lasso path {
          stroke: rgb(80,80,80);
          stroke-width:2px;
      }

      .lasso .drawn {
          fill-opacity:.05 ;
      }

      .lasso .loop_close {
          fill:none;
          stroke-dasharray: 4,4;
      }

      .lasso .origin {
          fill:#3399FF;
          fill-opacity:.5;
      }

      .not_possible {
          fill: rgb(200,200,200);
      }

      .possible {
          fill: #EC888C;
      }

      .selected {
          fill: steelblue;
      }


.roc_line{
      fill: none;
      stroke-width: 2;
      opacity: .9;
    }
.roc_selected_circle{
  
}
.myscatterplot{
  background-color: #f4f4f4!important;
}
#standard-basic{
  font-size: 18px!important;
  margin-left: 20px;
}
div.tooltip {	
  position: absolute;			
  text-align: center;							
  height: 18px;					
  padding:5px 12px;				
  font: 12px sans-serif;		
  background: rgb(39, 46, 56);	
  border: 0px;		
  border-radius: 8px;			
  pointer-events: none;
  color: white;			
}
.border_selected{
  stroke: rgba(0, 0, 0, 0.5);
  stroke-width: 2px;
}